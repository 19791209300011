import { animated, config, useTransition } from '@react-spring/web';
import * as React from 'react';
import { styled } from 'styled-components';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { mqLarge } from '../../styles/media-query';

export interface FlagProps extends React.ComponentProps<typeof AlertMessage> {
  open: boolean;
  dismissTimeout?: number;
}

const Flag: React.FC<FlagProps> = ({
  open,
  dismissTimeout = 0,
  onDismiss,
  ...alertProps
}) => {
  const transitions = useTransition(open, {
    config: config.stiff,
    from: { transform: 'translate3d(0, 3rem,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0, 3rem,0)', opacity: 0 },
  });

  React.useEffect(() => {
    if (open && dismissTimeout && onDismiss) {
      const timeoutId = setTimeout(() => {
        onDismiss();
      }, dismissTimeout);
      return () => clearTimeout(timeoutId);
    }
  }, [dismissTimeout, onDismiss, open]);

  return (
    <>
      {transitions(
        (styleProps, item) =>
          item && (
            <AnimatedWrapper style={styleProps}>
              <AlertMessage onDismiss={onDismiss} {...alertProps} />
            </AnimatedWrapper>
          ),
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: var(--space-1);
  right: var(--space-1);
  bottom: var(--space-1);
  z-index: var(--z-index-10);
  @media ${mqLarge} {
    bottom: var(--space-4);
    left: var(--space-4);
    right: auto;
  }
`;

const AnimatedWrapper = animated(Wrapper);

export default Flag;
