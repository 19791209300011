import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useAppState } from '../context/app-context';

export function initSentry() {
  if (process.env.NODE_ENV === 'development') return;

  Sentry.init({
    dsn: 'https://f779d1a6be8cc91bb8be14d519776e40@sentry.ops.madisoft.it/6',
    environment: process.env.NODE_ENV,
    release: process.env.RELEASE_NAME,
    tracesSampleRate: 0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

export function useSentryContext() {
  const { user } = useAppState();

  React.useEffect(() => {
    Sentry.setTag('codiceCliente', user.schoolCode);
    Sentry.setContext('session', {
      codiceCliente: user.schoolCode,
      modalita: user.accessMode,
      scuola: user.schoolName,
    });
  }, [user.schoolCode, user.accessMode, user.schoolName]);
}
