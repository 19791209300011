export function formatCoords(position: GeolocationPosition): string {
  const { coords } = position;
  return `${coords.latitude.toFixed(7)},${coords.longitude.toFixed(7)}`;
}

export function formatDistance(distanceInMeters: number): string {
  const [value, unit] =
    distanceInMeters < 1000
      ? [distanceInMeters, ' m']
      : [distanceInMeters / 1000, ' km'];
  return (
    Number.parseFloat(value.toFixed(1))
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + unit
  );
}

type GeographicCoordinates = {
  latitude: number;
  longitude: number;
};

/**
 * Calcola la distanza tra due punti geografici utilizzando la formula di haversine
 * https://www.movable-type.co.uk/scripts/latlong.html
 *
 * @param {GeographicCoordinates} coords1 The coordinates of the first point.
 * @param {GeographicCoordinates} coords2 The coordinates of the second point.
 * @returns the distance in meters between the two points
 */
export function calculateDistanceInMeters(
  coords1: GeographicCoordinates,
  coords2: GeographicCoordinates,
): number {
  const { latitude: lat1, longitude: lon1 } = coords1;
  const { latitude: lat2, longitude: lon2 } = coords2;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const AVERAGE_RADIUS_OF_EARTH_IN_KM = 6371;
  const distanceinKm =
    AVERAGE_RADIUS_OF_EARTH_IN_KM *
    2 *
    Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return distanceinKm * 1000;

  function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }
}
