import * as React from 'react';
import { usePwaUpdateAvailable } from '../../hooks/pwa-helpers';
import { useSessionStorage } from '../../hooks/use-storage';
import Button from '../Button/Button';
import Flag from '../Flag/Flag';

const UpdateAvailableFlag: React.FC = () => {
  const [dismissed, setDismissed] = useSessionStorage(
    'update-dismissed',
    false,
  );
  const [updateAvailable, update] = usePwaUpdateAvailable();
  return (
    <Flag
      open={updateAvailable && !dismissed}
      title="Aggiornamento disponibile"
      onDismiss={() => setDismissed(true)}
    >
      <Button onClick={update} $appearance="text">
        Aggiorna
      </Button>
    </Flag>
  );
};

export default UpdateAvailableFlag;
