import {
  differenceInDays,
  differenceInMinutes,
  format,
  subDays,
} from 'date-fns';
import { it } from 'date-fns/locale';

export const formatDate = (date: number | Date, dateFormat: string): string =>
  format(date, dateFormat, { locale: it });

export const dateDiffInDays = (
  dateLeft: Date | number,
  dateRight: Date | number,
): number => differenceInDays(dateLeft, dateRight);

export const dateDiffInMinutes = (
  dateLeft: Date | number,
  dateRight: Date | number,
): number => differenceInMinutes(dateLeft, dateRight);

export const getLastWeek = (date: Date) => subDays(date, 7);
