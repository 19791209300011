import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import CloudOff from '../../assets/cloud-off-solid.svg';

export function ErrorFallback() {
  const errorTitle = 'Si è verificato un errore';
  const errorMessage =
    'Ci scusiamo per il disagio! ' +
    'Siamo continuamente al lavoro per correggere errori come questo.';
  return (
    <Center>
      <CloudOff />
      <Title>Oops! {errorTitle}</Title>
      <p>{errorMessage}</p>
      <A to="/">Torna indietro</A>
    </Center>
  );
}

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spaceL};
  & svg {
    width: 5rem;
    height: 5rem;
    fill: ${({ theme }) => theme.colorPrimary};
    margin-bottom: ${({ theme }) => theme.spaceM};
  }
`;

const Title = styled.h2`
  margin-top: ${({ theme }) => theme.spaceL};
  margin-bottom: ${({ theme }) => theme.spaceM};
`;

const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  min-height: 44px;
  margin-top: ${({ theme }) => theme.spaceM};
  padding: 0 ${({ theme }) => theme.spaceS};
  font-weight: 500;
  color: ${({ theme }) => theme.colorText};
  &:hover,
  :focus {
    color: ${({ theme }) => theme.colorPrimaryDark};
    background-color: ${({ theme }) => theme.colorHover};
  }
`;
