import { animated, config, useTransition } from '@react-spring/web';
import * as React from 'react';
import { styled } from 'styled-components';
import { Row } from '../layout/flex-containers';

type StatusBarProps = React.HTMLAttributes<HTMLDivElement> & {
  visible: boolean;
  SvgIcon?: React.FC<React.SVGProps<SVGElement>>;
};

const StatusBar: React.FC<StatusBarProps> = ({
  visible,
  SvgIcon,
  children,
  ...divProps
}) => {
  const transitions = useTransition(visible, {
    config: config.stiff,
    from: { transform: 'translate3d(0, -2rem, 0)' },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: { transform: 'translate3d(0, -2rem, 0)' },
  });
  return (
    <>
      {transitions(
        (styleProps, item) =>
          item && (
            <Bar role="alert" style={styleProps} $center {...divProps}>
              {SvgIcon && (
                <SvgIcon width="12" height="12" className="mx-2" aria-hidden />
              )}
              {children}
            </Bar>
          ),
      )}
    </>
  );
};

const Bar = styled(animated(Row))`
  transition: background-color 0.2s ease-out;
  background-color: var(--neutral-16);
  color: var(--neutral-1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export default StatusBar;
