import { AppState } from '../models';

type StorageModel = {
  'user-data': AppState;
  'sync-date': string;
  'is-first-access': boolean;
};

export function getObject<Key extends keyof StorageModel>(
  key: Key,
): StorageModel[Key] | null {
  const item = window.localStorage.getItem(key);
  if (!item) return null;
  return JSON.parse(item);
}

export function setObject<Key extends keyof StorageModel>(
  key: Key,
  obj?: StorageModel[Key] | null,
): void {
  if (obj) {
    const item = JSON.stringify(obj);
    window.localStorage.setItem(key, item);
  } else {
    window.localStorage.removeItem(key);
  }
}

export function removeObject(key: keyof StorageModel): void {
  window.localStorage.removeItem(key);
}
