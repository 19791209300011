import * as React from 'react';
import { styled } from 'styled-components';
import Success from '../../assets/check-circle-solid.svg';
import Danger from '../../assets/exclamation-circle-solid.svg';
import Warning from '../../assets/exclamation-triangle-solid.svg';
import Info from '../../assets/info-circle-solid.svg';
import Cross from '../../assets/times-solid.svg';
import { Row } from '../layout/flex-containers';

type AlertMessageProps = React.PropsWithChildren<{
  title: string;
  appearance?: 'info' | 'success' | 'warning' | 'danger';
  theme?: 'solid' | 'default';
  onDismiss?: () => void;
}>;

const defaultTheme = {
  info: {
    icon: Info,
    color: 'var(--text)',
    fill: 'var(--info-solid)',
    background: 'var(--info-background)',
  },
  success: {
    icon: Success,
    color: 'var(--text)',
    fill: 'var(--success-solid)',
    background: 'var(--success-background)',
  },
  warning: {
    icon: Warning,
    color: 'var(--text)',
    fill: 'var(--warning-solid)',
    background: 'var(--warning-background)',
  },
  danger: {
    icon: Danger,
    color: 'var(--text)',
    fill: 'var(--danger-solid)',
    background: 'var(--danger-background)',
  },
};

const solidTheme = {
  info: {
    icon: Info,
    color: 'var(--on-primary)',
    fill: 'var(--on-primary)',
    background: 'var(--info-solid)',
  },
  success: {
    icon: Success,
    color: 'var(--on-primary)',
    fill: 'var(--on-primary)',
    background: 'var(--success-solid)',
  },
  warning: {
    icon: Warning,
    color: 'var(--on-primary)',
    fill: 'var(--on-primary)',
    background: 'var(--warning-solid)',
  },
  danger: {
    icon: Danger,
    color: 'var(--on-primary)',
    fill: 'var(--on-primary)',
    background: 'var(--danger-solid)',
  },
};

const themes = {
  default: defaultTheme,
  solid: solidTheme,
};

const AlertMessage = ({
  title,
  appearance = 'info',
  theme = 'default',
  onDismiss,
  children,
}: AlertMessageProps) => {
  const messageTheme = themes[theme] || defaultTheme;
  const {
    color,
    background,
    icon: Icon,
    fill,
  } = messageTheme[appearance] || messageTheme.info;
  return (
    <Wrapper
      $color={color}
      $background={background}
      role="alert"
      aria-labelledby="alert-message-title"
    >
      <Icon width="16" height="16" color={fill} style={{ flexShrink: 0 }} />
      <Content>
        <Heading id="alert-message-title">{title}</Heading>
        {children}
      </Content>
      {onDismiss ? (
        <CloseButton aria-label="Chiudi alert" onClick={onDismiss}>
          <Cross
            width="12"
            height="12"
            fill="var(--neutral-12)"
            color={color}
          />
        </CloseButton>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled(Row)<{ $color: string; $background: string }>`
  padding: var(--space-3);
  border-radius: var(--radius);
  color: ${props => props.$color};
  background-color: ${props => props.$background};
`;

const Content = styled.div`
  margin: 0 var(--space-3);
`;

const Heading = styled.h2`
  margin-top: 0;
  margin-bottom: var(--space-1);
`;

const CloseButton = styled.button`
  position: absolute;
  cursor: pointer;
  top: var(--space-1);
  right: var(--space-1);
  background: none;
  border: none;
  line-height: 0;
  padding: var(--space-1);
`;

export default AlertMessage;
