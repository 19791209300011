import * as React from 'react';

export function usePwaUpdateAvailable(): [
  boolean,
  (ev: React.SyntheticEvent) => void,
] {
  const [updateAvailable, setUpdateAvailable] = React.useState(false);
  const newWorker = React.useRef<ServiceWorker | null>(null);
  const updateServiceWorker = React.useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault();
    newWorker.current?.postMessage({ type: 'SKIP_WAITING' });
  }, []);

  React.useEffect(() => {
    let refreshing = false;
    const checkForUpdate = async function () {
      const reg = await navigator.serviceWorker.getRegistration();
      if (reg) {
        reg.addEventListener('updatefound', () => {
          const installingWorker = reg.installing;
          newWorker.current = installingWorker;
          installingWorker?.addEventListener('statechange', () => {
            if (
              installingWorker?.state === 'installed' &&
              navigator.serviceWorker.controller
            ) {
              setUpdateAvailable(true);
            }
          });
        });

        if (reg.waiting && navigator.serviceWorker.controller) {
          newWorker.current = reg.waiting;
          setUpdateAvailable(true);
        }
      }

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
      });
    };

    if ('serviceWorker' in navigator) {
      checkForUpdate();
    }
    return () => {
      refreshing = false;
    };
  }, []);

  return [updateAvailable, updateServiceWorker];
}
