import * as React from 'react';
import { styled } from 'styled-components';

type InfoWithImageProps = {
  title: string;
  message?: string;
  image?: {
    src: string | React.FC<React.SVGProps<SVGElement> & { title?: string }>;
    alt: string;
    size?: number;
  };
};

function InfoWithImage({ title, message, image }: InfoWithImageProps) {
  return (
    <Wrapper>
      <Image image={image} />
      <h2>{title}</h2>
      {message ? <P>{message}</P> : null}
    </Wrapper>
  );
}

function Image({ image }: Pick<InfoWithImageProps, 'image'>) {
  if (!image) return null;

  const { src: Img, alt, size = 200 } = image;
  if (typeof Img === 'string') {
    return <img src={Img} alt={alt} width={size} height={size} />;
  }

  return <Img width={size} height={size} title={alt} />;
}

const Wrapper = styled.div`
  text-align: center;
`;

const P = styled.p`
  text-align: left;
`;

export default InfoWithImage;
