import * as React from 'react';
import * as storage from '../shared-logic/storage';
import { AppState } from '../models';
import { isValidForGeolocation } from '../shared-logic/gate-position';

type AppContextType = [AppState | null, (action: Action) => void];

type Action =
  | { type: 'clear' }
  | { type: 'init'; value: AppState }
  | { type: 'update'; value: Partial<AppState> };

const AppContext = React.createContext<AppContextType | null>(null);

function useAppContext(): AppContextType {
  const appContext = React.useContext(AppContext);
  if (!appContext) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return appContext;
}

function useAppState(): AppState {
  const [appState] = useAppContext();
  return appState!;
}

const AppContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const initialValue = storage.getObject('user-data');
  const [state, dispatch] = React.useReducer(appReducer, initialValue);
  React.useEffect(() => {
    storage.setObject('user-data', state);
    if (state === null) {
      storage.removeObject('sync-date');
    }
  }, [state]);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};

function appReducer(state: AppState | null, action: Action): AppState | null {
  switch (action.type) {
    case 'clear':
      return null;
    case 'init':
      return action.value;
    case 'update':
      return state ? { ...state, ...action.value } : null;
    default:
      return state;
  }
}

/**
 * Ritorna le posizioni disponibili per l'utente autenticato in base alla modalità
 * di accesso. Per gli utenti 'mobile' filtra solo le posizioni che hanno i valori
 * per la geolocalizzazione impostati, cioè ritorna `Required<GatePosition>[]`.
 * @returns le posizioni valide per l'utente autenticato.
 */
function usePositions(): AppState['positions'] {
  const { user, positions } = useAppState();
  return user.accessMode === 'mobile'
    ? positions.filter(isValidForGeolocation)
    : positions;
}

export { AppContextProvider, useAppContext, useAppState, usePositions };
