import * as React from 'react';
import { styled } from 'styled-components';
import { mqMedium, mqSmall } from '../../styles/media-query';
import OfflineStatusBar from '../StatusBar/OfflineStatusBar';

type LayoutProps = React.PropsWithChildren<{
  title: string;
  nav?: React.ReactNode;
}>;

const Layout = ({ title, nav, children }: LayoutProps) => {
  return (
    <>
      <OfflineStatusBar />
      <Header>
        <H1>{title}</H1>
        <Nav>{nav}</Nav>
      </Header>
      <Main>{children}</Main>
    </>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: center;
  padding: 0 var(--space-3);
`;

const H1 = styled.h1`
  flex: 1;
  @media ${mqSmall} {
    flex: unset;
  }
`;

const Nav = styled.nav`
  margin-left: var(--space-2);
  margin-top: 18px;
  @media ${mqMedium} {
    text-align: center;
    position: absolute;
    right: var(--space-3);
  }
`;

const Main = styled.main`
  margin: auto var(--space-2);
  @media ${mqSmall} {
    margin: auto;
    max-width: var(--layout-width);
  }
`;

export default Layout;
