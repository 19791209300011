import { BaseOption, GatePosition } from '../models';
import { calculateDistanceInMeters, formatDistance } from './position';
import { formatDate } from './date';
import { isWithinInterval } from 'date-fns';
import { HandledError } from './handled-error';

export const gatePositionToOption = ({
  id,
  name,
}: GatePosition): BaseOption => ({
  label: name,
  value: `${id}`,
});

export const isValidForGeolocation = (
  gatePosition: GatePosition,
): gatePosition is Required<GatePosition> =>
  typeof gatePosition.latitude === 'number' &&
  typeof gatePosition.longitude === 'number' &&
  typeof gatePosition.radius === 'number';

export const isNearGatePosition = (
  gatePosition: Required<GatePosition>,
  coords: GeolocationCoordinates,
): boolean =>
  calculateDistanceInMeters(gatePosition, coords) < gatePosition.radius;

export function assertIsInsideGateArea(
  gatePosition: Required<GatePosition>,
  coords: GeolocationCoordinates,
): void {
  const distance = calculateDistanceInMeters(gatePosition, coords);
  if (distance > gatePosition.radius) {
    throw new Error(
      `La tua posizione risulta essere a ${formatDistance(
        distance,
      )} di distanza da ${gatePosition.name}. ${positionNotVerifiedHint}`,
    );
  }
}

const positionNotVerifiedHint =
  "Assicurati che il GPS sia attivo e attendi che abbia rilevato correttamente la tua posizione, poi riprova a registrare l'ingresso/uscita.";

export function assertIsWithinGateOpeningHours(
  gatePosition: GatePosition,
): boolean {
  const now = new Date();
  const openingTime = new Date(gatePosition.openingTime);
  const closingTime = new Date(gatePosition.closingTime);

  if (
    !isWithinInterval(now, {
      start: getTodayTime(openingTime),
      end: getTodayTime(closingTime),
    })
  ) {
    throw new HandledError({
      title: 'Attenzione',
      message: `Tentativo di registrazione fuori dall'orario di apertura della postazione che va dalle ${formatDate(
        openingTime,
        'HH:mm',
      )} alle ${formatDate(
        closingTime,
        'HH:mm',
      )}. La registrazione verrà ignorata.`,
    });
  }

  return true;
}

function getTodayTime(date: Date): Date {
  const now = new Date();
  now.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
  return now;
}
