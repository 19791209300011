import * as React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import BrowserValidator from './components/BrowserValidator/BrowserValidator';
import App from './pages/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import { initSentry } from './integration/sentry';
import { ErrorFallback } from './pages/ErrorFallback/ErrorFallback';

initSentry();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserValidator>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <App />
      </Sentry.ErrorBoundary>
    </BrowserValidator>
  </React.StrictMode>,
);

serviceWorkerRegistration.register();
