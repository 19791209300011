import * as React from 'react';
import { css, styled } from 'styled-components';

type FlexProps = {
  $center?: boolean;
  $flexWrap?: React.CSSProperties['flexWrap'];
  $justifyContent?: React.CSSProperties['justifyContent'];
  $alignItems?: React.CSSProperties['alignItems'];
};

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${props =>
    props.$center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${props =>
    props.$flexWrap &&
    css`
      flex-wrap: ${props.$flexWrap};
    `}

  ${props =>
    props.$justifyContent &&
    css`
      justify-content: ${props.$justifyContent};
    `}

  ${props =>
    props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;
