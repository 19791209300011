import { useIsOnline } from '../../hooks/use-is-online';
import CloudOff from '../../assets/cloud-off-solid.svg';
import StatusBar from './StatusBar';

function OfflineStatusBar() {
  const online = useIsOnline();
  return (
    <StatusBar
      visible={!online}
      SvgIcon={CloudOff}
      aria-labelledby="offline-status"
    >
      <span id="offline-status">Nessuna connessione Internet</span>
    </StatusBar>
  );
}

export default OfflineStatusBar;
