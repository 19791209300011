import { css, styled } from 'styled-components';
import { Row } from '../layout/flex-containers';

type ButtonProps = {
  $appearance?: 'primary' | 'text' | 'default';
  $size?: 'small' | 'medium' | 'large';
};

export const ButtonGroup = styled(Row)`
  margin: var(--space-2) 0;
`;

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  display: inline-block;
  border: none;
  border-radius: 0.25rem;
  font-weight: bold;
  transition:
    background-color 0.2s ease-in,
    color 0.2s ease-in;

  &:disabled {
    opacity: 0.5;
  }

  ${ButtonGroup} & {
    &:not(:first-of-type) {
      margin-left: var(--space-2);
    }
  }

  ${props =>
    props.$appearance === 'primary'
      ? css`
          background-color: var(--primary);
          color: var(--on-primary);
          &:hover {
            background-color: var(--primary-hover);
          }
          &:active {
            background-color: var(--primary-active);
          }
        `
      : props.$appearance === 'text'
        ? css`
            background: none;
            color: var(--primary);
            &:hover {
              color: var(--primary-hover);
              background-color: var(--neutral-1);
            }
            &:active {
              color: var(--on-primary);
              background-color: var(--primary-active);
            }
          `
        : css`
            background-color: var(--neutral-3);
            color: var(--text);
            &:hover {
              background-color: var(--neutral-2);
              color: var(--primary-hover);
            }
            &:active {
              background-color: var(--neutral-4);
            }
          `}

  ${props =>
    props.$size === 'large'
      ? css`
          padding: var(--space-3) var(--space-4);
          font-size: 1.1rem;
        `
      : props.$size === 'small'
        ? css`
            padding: var(--space-1) var(--space-2);
            font-size: 0.9rem;
          `
        : css`
            padding: var(--space-2) var(--space-3);
            font-size: 1rem;
          `}
`;

export default Button;
