import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import UpdateAvailableFlag from '../../components/pwa-helper-components/UpdateAvailableFlag';
import { AppContextProvider, useAppContext } from '../../context/app-context';

const AuthenticatedApp = React.lazy(
  () => import('./components/AuthenticatedApp'),
);
const UnauthenticatedApp = React.lazy(() => import('../SignIn/SignIn'));

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <React.Suspense fallback="Loading...">
          <Routes>
            <Route path="login" element={<UnauthenticatedApp />} />
            <Route
              path="*"
              element={
                <RequireAuth>
                  <AuthenticatedApp />
                </RequireAuth>
              }
            />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <UpdateAvailableFlag />
    </AppContextProvider>
  );
}

function RequireAuth({ children }: { children: React.ReactElement }) {
  const [appState] = useAppContext();
  if (!appState?.user) {
    return <Navigate to="login" replace />;
  }
  return children;
}

export default App;
